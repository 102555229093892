<template>
  <div class="count-down">
    <transition name="scale-fade">
      <img v-if="ready" class="countdown-img" :src="imgSrc" alt="countdown" />
    </transition>
    <audio ref="audio" :src="require('@/assets/audio/countdown.mp3')">
      浏览器版本过低，请尽快升级
    </audio>
    <div class="message">
      <div class="message-title">【温馨提示】</div>
      <div class="message-content blink">进入答题有知识点解析</div>
      <div class="message-content blink">可以温故知新……</div>
    </div>
  </div>
</template>
<script>
import img1 from '@/assets/1@2x.png'
import img2 from '@/assets/2@2x.png'
import img3 from '@/assets/3@2x.png'
import { Toast } from 'vant'
import storage from 'store'

export default {
  name: 'Countdown',
  data() {
    return {
      ready: false,
      count: 0,
      imgs: [img3, img2, img1]
    }
  },
  computed: {
    imgSrc() {
      return this.imgs[this.count]
    }
  },
  mounted() {
    if (this.$store.state.gameStart) {
      this.getQuestion()
    } else {
      // this.$router.replace('/home')
    }
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  methods: {
    playCountdown() {
      if (this.count === 3) {
        clearInterval(this.interval)
        // 使路由在quiz页返回直接跳到首页
        this.$router.replace('/question')
        return
      }
      this.ready = true
      // if (!this.mute) {
      this.$refs.audio.play()
      // }
      setTimeout(() => {
        this.ready = false
        this.count += 1
      }, 1000)
    },
    getQuestion(params) {
      return this.$http({
        url: '/xt-exam/etQuestion/question',
        params
      }).then(response => {
        if (response.code == 200) {
          storage.set('ANSWER_QUESTIONID', response.data.id)

          this.$nextTick(() => {
            this.interval = setInterval(() => {
              this.playCountdown()
            }, 1300)
          })
          try {
            const questionList = response.data
            questionList.list = questionList.list
              .map(question => ({
                ...question,
                questionId: question.id,
                ...JSON.parse(question.content)
              }))
              .map(question => ({
                ...question,
                questionTitle: question.title,
                questionOptions: question.choiceList.map(choice => ({
                  key: Object.keys(choice)[0],
                  value: choice[Object.keys(choice)[0]]
                }))
              }))
            this.$store.commit('SET_QUESTIONS', questionList)
          } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error)
          }
        } else {
          Toast.fail({
            message: response.data,
            onClose: () => {
              this.$router.push('/')
            }
          })
        }
      })
    }
  }
}
</script>
<style lang="less">
.count-down {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .countdown-img {
    width: 36%;
  }
}
.scale-fade-enter-active {
  transition: all 0.6s;
}
.scale-fade-leave-active {
  transition: all 0.2s;
}
.scale-fade-enter {
  transform: scale(0.3);
  opacity: 0;
}
.scale-fade-leave-to {
  opacity: 0;
}
.message {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  bottom: 16vh;
  color: #ffffff;

  &-title {
    font-size: 28px;
    font-weight: 600;
  }
  &-content {
    margin-top: 2vh;
    font-size: 22px;
    animation: blink 1s linear infinite;
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0.2;
  }
}
</style>
